.calendar{
    align-items: center;
    background: url('../../../images/calendar-title.png'), $secondary;
    background-repeat: no-repeat;
    background-position: 0 40px;
    background-size: 80px;
    display: flex;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    padding: 40px;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
        background: url('../../../images/calendar-mobile-title.png'), $secondary;
        background-repeat: no-repeat;
        background-position: 20px 40px;
        flex-direction: column;
        height: auto;
        padding: 100px 40px 40px;
    }


    &-content{
        margin: 0 auto;
        padding: 20px;
        width: 60%;

        @media (max-width: 1024px) {
            width: 75%;
        }

        @media (max-width: 768px) {
            margin-top: 60px;
            padding: 0;
            width: 100%;
        }
    }

    &-title{
        color: $overlay-4;
        font-family: $blogger;
        font-size: 100px;
        line-height: 100%;
        letter-spacing: 0.05em;
        position: absolute;
        bottom: -20px;
        right: -20px;
        text-align: right;
        text-transform: uppercase;

        @media (max-width: 768px) {
            display: none;
        }
    }
}
