@font-face {
    font-family: 'Blogger Sans';
    src: url('../../../../public/fonts/Blogger_Sans/BloggerSans-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: url('../../../../public/fonts/Blogger_Sans/BloggerSans.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: url('../../../../public/fonts/Blogger_Sans/BloggerSans-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Blogger Sans';
    src: url('../../../../public/fonts/Blogger_Sans/BloggerSans-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../../../public/fonts/Lato/Lato-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../../../public/fonts/Lato/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../../../public/fonts/Lato/Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../../../public/fonts/Lato/Lato-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('../../../../public/fonts/Lato/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
