.btn{
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 0px $shadow50;
    font-family: $blogger;
    font-size: 24px;
    font-weight: $bold;
    letter-spacing: 0.05em;
    padding: 10px 40px;
    text-align: center;
    transition: 0.3s;
    z-index: 50;

    @media (max-width: 1024px) {
        font-size: 18px;
    }

    &:hover{
        box-shadow: 8px 8px 0px $shadow50;
        cursor: pointer;
    }

    &-primary{
        background-color: $primary;
    }

    &-secondary--dark{
        background-color: $secondary-dark;
        color: $primary;
    }
}