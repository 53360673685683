.about-us{
    background: $secondary;
    width: 100%;
    
    &-container{
        align-items: center;
        display: flex;
        height: 100vh;
        justify-content: flex-end;
        padding: 40px;

        @media (max-width: 1024px) {
            flex-direction: column;
            height: auto;
            justify-content: center;
            padding: 80px 40px;
        }

        .about-us-title{
            color: $primary-dark;
            font-family: $blogger;
            font-style: normal;
            font-weight: $bold;
            font-size: 145px;
            line-height: 152px;
            text-align: right;
            letter-spacing: 0.05em;
            position: relative;
            text-transform: uppercase;
            transform: translateX(-40%);
            width: 60%;

            @media (max-width: 1024px) {
                font-size: 80px;
                line-height: 95px;
                margin-bottom: 20px;
                text-align: left;
                width: 45%;
            }

            @media (max-width: 520px) {
                font-size: 55px;
                line-height: 67px;
                width: 55%;
            }
            
            &::after{
                content: "Quem somos nós";
                position: absolute;
                right: -8px;
                top: 8px;
                color: $overlay-5;

                @media (max-width: 1024px) {
                    top: 3px;
                }

                @media (max-width: 520px) {
                    right: -25px;
                }
            }
        }

        .about-us-content{
            background: $overlay-5;
            background-blend-mode: hard-light;            
            box-shadow: 10px 10px 0px rgba(51, 51, 51, 0.3);
            border-radius: 20px;
            color: $primary;
            font-size: 22px;
            line-height: 1.5;
            letter-spacing: 0.05em;
            padding: 60px;
            width: 70%;

            @media (max-width: 1024px) {
                width: 80%;
            }

            @media (max-width: 520px) {
                font-size: 16px;
                padding: 20px;
                width: 100%;
            }

            p{
                margin-bottom: 30px;

                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }    
}