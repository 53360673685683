.filters {
    color: $white;
    display: flex;
    padding: 15px 30px;
    width: 100%;


    .tag-filter{
        align-items: center;
        border-radius: 25px;
        display: flex;
        font-size: 12px;
        margin-right: 20px;
        padding: 10px 15px;
        text-transform: uppercase;
    
        button{
            background: none;
            background-image: url('../../../svg/icon-close-filter.svg');
            background-repeat: no-repeat;
            background-position: center;
            border: none;
            height: 20px;
            margin-left: 10px;
            width: 20px;

            &:hover{
                cursor: pointer;
            }
        }
    }

    button{
        background: none;
        border: none;        
        color: inherit;
        font-size: 12px;
        margin-left: auto;
        text-transform: uppercase;

        &:hover{
            cursor: pointer;
        }
    }
}
