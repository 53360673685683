.navbar-mobile{
    display: none;
    position: relative;

    @media (max-width: 768px) {
        display: block;
    }

    &-overlay{
        visibility: hidden;
        height: 0;
    }

    .btn-menu-mobile{
        background: transparent;
        border: none;
        display: none;
        position: relative;

        @media (max-width: 768px) {
            display: block;
        }

        &::before{
            border-bottom: 3px solid $white;
            border-radius: 2px;
            content: '';
            display: block;
            margin-left: auto;
            margin-bottom: 10px;
            width: 25px;
            transition: 0.5s linear; 
        }

        &::after{
            border-bottom: 3px solid $white;
            content: '';
            display: block;
            width: 35px;
            border-radius: 2px;
            transition: 0.5s linear; 
        }

        &:hover{
            cursor: pointer;
        }
    }

    .navbar-menu{
        align-items: flex-end;
        background: $primary;
        flex-direction: column;
        height: 0;
        opacity: 0;
        padding: 40px 20px;
        position: absolute;
        right: -20px;
        top: 55px;
        transition: 0.5s;
        visibility: hidden;
        width: 70vw;
        z-index: 10;

        .menu-item{
            border-bottom: 2px solid $secondary-dark;
            margin-bottom: 10px;
            text-align: end;
            width: 90%;

            &.active{
                &::before{
                    background: $secondary-dark;
                    border-radius: 50%;
                    content: '';
                    display: inline-flex;
                    height: 8px;
                    margin-right: 15px;
                    width: 8px;
                }
            }
        }
    }

    &.is-open{
        .btn-menu-mobile::before{
            width: 35px;
            transform: rotate(-315deg);
        }

        .btn-menu-mobile::after{
            position: absolute;
            top: 0px;
            left: 0px;
            transform: rotate(135deg);
        }

        .navbar-menu{
            height: calc(100vh - 98px);
            visibility: visible;
            opacity: 1;
            transition: 0.3s;
        }

        .navbar-mobile-overlay{
            background: $black;
            height: 100vh;
            opacity: 0.3;
            position: absolute;
            right: -20px;
            top: 57px;
            visibility: visible;
            width: 100vw;
            z-index: -1;
        }
    }
}