.actions{
    background: url('../../../images/action-title.png'), $secondary;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 300px;
    height: 100vh;
    padding: 0 20px;
    position: relative;

    @media (max-width: 768px) {
        padding: 100px 40px 40px;
        background-position: 5% 10px;
        background-size: 200px;
        height: auto;
    }

    .actions-content{        
        align-content: center;
        align-items: center;
        display: grid;
        grid-template-areas:
            "slider content"
            "slider button";
        grid-template-columns: 60% auto;
        grid-template-rows: auto;
        height: 100vh;
        justify-content: center;
        justify-items: start;
        transform: translateY(-50px);

        @media (max-width: 768px) {
            display: grid;
            grid-template-areas: 
            "content"
            "slider"
            "button";
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            justify-items: center;
            height: auto;
        }

        &-slider{
            grid-area: slider;
            height: 490px;
            max-width: 420px;
            margin: auto;


            @media (max-width: 768px) {
                margin-bottom: 40px;
            }

            @media (max-width: 520px) {
                height: 425px;
                max-width: 330px;
            }

            .slider-content{
                display: flex;
                margin: 0 15px;

            }

            .slick-prev {
                left: -75px;
            }
    
            .slick-next {
                right: -40px;
            }
            
            .slick-prev::before, .slick-next::before{
               
                border-radius: 50%;
                filter: drop-shadow(4px 4px 0px $shadow50);
                content: '';
                display: block;
                font-size: 40px;
                height: 40px;
                width: 40px;

                @media (max-width: 520px) {
                    display: none;
                }
            }

            .slick-prev::before{
                background: url('../../../svg/icon-arrow-left.svg'), $primary;
                background-position: center;
                background-repeat: no-repeat;
            }

            .slick-next::before{
                background: url('../../../svg/icon-arrow-right.svg'), $primary;
                background-position: center;
                background-repeat: no-repeat;
            }
        
            .slick-dots li button:before{
                font-size: 14px;
                color: #D9D9D9;
                padding-top: 20px
            }
        }

        &-text{
            align-self: end;
            grid-area: content;
            padding: 20px 0;
            width: 100%;

            @media (max-width: 768px) {
                margin-top: 40px;
                width: 80%;
            }

            @media (max-width: 520px) {
                width: 90%;
            }

            p{
                color: $white;
                font-weight: $bold;
                font-size: 20px;
                line-height: 1.5;
                letter-spacing: 0.05em;
                margin-bottom: 60px;

                @media (max-width: 520px) {
                   font-size: 16px;
                   margin-top: 20px;
                   margin-bottom: 20px;
                }

            }
        }

        &-btn{
            align-self: baseline;
            grid-area: button;
            margin-right: auto;

            @media (max-width: 768px) {
                margin: 60px 0 0;
            }
        }
    }

    &-title{
        color: $overlay-4;
        font-family: $blogger;
        font-weight: $bold;
        font-size: 100px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        bottom: 0;
        grid-area: title;

        @media (max-width: 768px) {
            display: none;
        }
    }
}