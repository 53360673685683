.action-card{
    border-radius: 20px;
    background: $overlay-5;
    background-blend-mode: hard-light;
    box-shadow: 6px 6px 3px $shadow50;
    width: 100%;
    height: 490px;
    max-width: 420px;

    @media (max-width: 520px) {
        height: 425px;
        max-width: 330px;
    }

    &-image{
        width: 100%;
        border-radius: 20px 20px 0 0;
    }

    &-info{
        border-radius: 0 0 20px 20px;
        font-family: $blogger;
        padding: 40px;

        @media (max-width: 520px) {
            padding: 20px;
        }

        &-title{
            color: $primary;
            font-weight: $bold;
            font-size: 30px;
            line-height: 33px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            @media (max-width: 520px) {
                font-size: 22px;
            }
        }

        &-tags{
            padding: 20px 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            justify-items: start;
            align-items: start;
            align-content: center;
            justify-content: space-evenly;
            min-width: auto;
            overflow: hidden;
        }
    }
}