.contact-us{
    align-items: center;
    background: url('../../../images/contact-title.png');
    background-repeat: no-repeat;
    background-position: 5% 20px;
    background-size: 450px;
    display: flex;
    justify-content: center;
    height: 100vh;
    padding: 40px;
    position: relative;

    @media (max-width: 768px) {
        background-size: 200px;
        height: auto;
        padding-top: 100px;
    }

    &-content{
        align-items: center;
        color: $white;
        display: grid;
        font-weight: $bold;
        grid-template-areas:
            "social form"
            "links form";
        grid-template-rows: auto auto;
        grid-template-columns: 30% 30%;
        justify-content: space-evenly;
        letter-spacing: 0.05em;
        width: 100%;        

        @media (max-width: 768px) {
            grid-template-areas:
            "social"
            "form"
            "links";
            grid-template-columns: 1fr;
        }

        &-info{
            grid-area: social;

            @media (max-width: 768px) {
                margin-bottom: 50px;
            }

            p{
                font-size: 24px;
                margin-bottom: 15px;

                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }

            .link{
                border-right: 2px solid;
                color: $white;
                font-size: 22px;
                padding: 0 10px;
                text-decoration: none;

                @media (max-width: 768px) {
                    font-size: 16px;
                }
                
                &:first-of-type{
                    padding-left: 0;
                }

                &:last-child{
                    border-right: none;
                }

                &:hover{
                    text-decoration: underline;
                }
            }
        }

        &-links{
            align-items: center;
            align-self: baseline;
            grid-area: links;
            display: flex;

            @media (max-width: 768px) {
                justify-content: space-around;
            }

            @media (max-width: 520px) {
                justify-content: space-between;
            }

            .menu-footer{
                font-size: 12px;
                list-style: none;
                margin-right: 40px;
                
                @media (max-width: 768px) {
                    font-size: 16px;
                    margin-right: 0;
                }

                li{
                    margin-bottom: 3px;
                }

                .link{
                    color: $white;
                    font-weight: $light;
                    text-decoration: none;

                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
