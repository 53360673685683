.navbar{
    @media (max-width: 768px) {
        display: none;
    }

    &-menu{
        align-items: center;
        display: flex;
        list-style: none;

        .menu-item{            
            padding: 12px 10px;

            .link{
                border: none;
                color: $secondary-dark;
                font-weight: $extra-bold;
                padding-bottom: 10px;
                text-decoration: none;
                text-transform: uppercase;
                transition: border 0.1s;

                &:hover{
                    border-bottom: 3px solid;

                    @media (max-width: 768px) {
                        border-bottom: none;
                    }
                }
            }
        }        
    }
}
