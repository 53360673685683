.content-empty{
    color: $white;
    font-family: $blogger;
    font-size: 26px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 0.05em;
    padding: 20px;
    text-transform: uppercase;

    p{
        font-size: 20px;
        margin-top: 20px;

        @media (max-width: 520px) {
            font-size: 20px;
        }
    }

    @media (max-width: 520px) {
        font-size: 16px;
    }
}