.header{
    background: $primary;
    backdrop-filter: blur(7.5px);
    position: relative;
    z-index: 999;
    width: 100%;

    &-container{
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
    }
}

.header-violet{
    background: $secondary;
    backdrop-filter: blur(7.5px);
    position: relative;
    z-index: 999;
    width: 100%;

    &-container{
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
    }

    .navbar-mobile{
        .navbar-menu{
            background-color: $secondary !important;
        }

        .link{
            color: $primary !important;
        }
    }
}

.header-sticky{
    background: #ffffff38;
    position: sticky;
    inset: 0;
    @extend .fade-in;
}
