.contact-us-content-form{
    grid-area: form;

    form{
        display: flex;
        flex-direction: column;
        padding: 0 30px;

        @media (max-width: 768px) {
            margin-bottom: 50px;
            padding: 0;
        }

        .form-control{                    
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            label{
                margin-bottom: 10px;
                padding-left: 10px;
                text-transform: uppercase;
            }

            input, textarea {
                border: none;
                border-radius: 10px;                        
                padding: 15px;
                outline: none;
            }

            textarea{
                height: 180px;

                &::placeholder{
                    font-family: $lato;
                }
                
                &::-ms-input-placeholder { /* Microsoft Edge */
                font-family: $lato;
                }
            }
        }
        
        .form-control-checkbox{
            display: inline-block;
            margin: 10px 0;
            vertical-align: middle;

            input[type="checkbox"] {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;

                & + label {
                    display: inline-block;
                    font-size: 10px;
                    padding-left: 40px;
                    position: relative;

                    &::before {
                        background: transparent;
                        border: 4px solid $secondary-dark;
                        border-radius: 5px;
                        box-shadow: 2px 2px 2px $shadow50;
                        content: "";
                        display: inline-block;
                        height: 22px;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translate(0, -50%);
                        transition: all 0.2s ease;
                        vertical-align: middle;
                        width: 22px;
                    }

                    &::after {
                        background: transparent;
                        border: solid $primary;
                        border-width: 0 4px 4px 0;
                        content: "";
                        height: 10px;   
                        position: absolute;
                        top: 50%;
                        left: 10px;
                        opacity: 0;
                        rotate: 45deg;
                        transform: translate(-50%, -50%);
                        transition: all 0.2s ease;
                        width: 5px;
                    }

                    & > a {
                        color: $white;
                    }
                }

                &:checked + label::before {
                    background: $secondary-dark;
                }

                &:checked + label::after {
                    opacity: 1;
                }
            }
        }

        .form-error{
            background: $light-red;
            border-radius: 5px;
            color: $error;
            font-size: 10px;
            margin-top: 10px;
            padding: 5px;
              
              &::before {
                display: inline;
                content: "⚠ ";
              }
        }
        
        .btn-form{
            margin: 20px auto;
            padding: 10px 40px;
            width: fit-content;

            &:disabled{
                background-color: $gray;
                color: $black;

                &:hover{
                    cursor: not-allowed;
                }
            }
        }                
    }
}
