.card{
    border-radius: 20px;
    background: $overlay-5;
    background-blend-mode: hard-light;
    box-shadow: 6px 6px 3px $shadow50;
    width: 100%;
    height: 490px;
    max-width: 380px;

    @media (max-width: 520px) {
        height: auto;
        max-width: 300px;
    }

    &:hover{
        cursor: pointer;
    }

    &-image{
        border-radius: 20px 20px 0 0;
        height: 195px;
        object-fit: cover;
        width: 100%;
    }

    &-info{
        border-radius: 0 0 20px 20px;
        font-family: $blogger;
        padding: 40px;

        @media (max-width: 520px) {
            padding: 20px;
        }

        &-title{
            color: $primary;
            display: -webkit-box;
            font-weight: $bold;
            font-size: 26px;
            height: 60px;
            line-height: 33px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            @media (max-width: 520px) {
                font-size: 20px;
            }
        }

        &-tags{
            align-content: center;
            align-items: start;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            justify-content: space-evenly;
            justify-items: start;
            min-width: auto;
            overflow: hidden;
            padding: 20px 0;
        }
    }
}
