.we-do{
    margin: 100px 0;
    width: 100%;

    @media (max-width: 1024px) {
        margin: 0;
    }

    &-content{
        align-items: center;
        display: flex;
        height: 100vh;
        padding: 40px;
        transform: translateX(-180px);
        width: 100%;

        @media (max-width: 1024px) {
            align-items: flex-start;
            flex-direction: column-reverse;
            height: auto;
            padding: 80px 40px;
            transform: translateX(0px);
        }
    

        &--first-title{
            background-blend-mode: overlay;
            color: #e23d0fba;
            font-family: $blogger;
            font-size: 147px;
            line-height: 168px;
            text-align: right;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            width: 35%;

            @media (max-width: 1024px) {
                display: none;
            }
        }

        &-text{
            background-image: url('../../../images/logo-white.png');
            background-repeat: no-repeat;
            background-position: top right;
            background-size: 170px;
            color: $secondary-dark;
            font-size: 26px;
            line-height: 36px; 
            letter-spacing: 0.05em;
            padding: 40px 0px 40px 60px;
            width: 45%;

            @media (max-width: 1024px) {                
                padding: 60px;
                width: 80%;
            }

            @media (max-width: 520px) {                
                background-size: 90px;
                font-size: 16px;
                line-height: 20px;         
                padding: 50px 20px 40px 0;
                width: 100%;
            }

            p {
                margin-bottom: 30px;
                font-weight: $bold;
            }
        }

        &--last-title{
            color: $secondary;
            font-family: $blogger;
            font-size: 147px;
            line-height: 168px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            position: relative;
            transform: translateX(90px);
            width: 25%;

            @media (max-width: 1024px) {
                display: none;
            }

            &::after{
                color: $overlay-3;
                content: "O que faze \A mos";
                position: absolute;
                left: -4px;
                top: -4px;
            }
        }
        
        &--title-mobile{
            display: none;

            @media (max-width: 1024px) {
                color: $secondary;
                display: block;
                font-family: $blogger;
                font-size: 80px;
                line-height: 95px;
                margin-bottom: 20px;
                text-align: left;
                letter-spacing: 0.05em;
                text-transform: uppercase;   
                transform: translateX(60px);   
                width: 45%;
            }

            @media (max-width: 520px) {
                font-size: 55px;
                line-height: 67px;
                margin-bottom: 0;
                transform: translateX(0px);
                width: 100%;
            }

            &::after{
                color: $overlay-3;
                content: "O que fazemos";
                position: absolute;
                left: -2px;
                top: -3px;
            }
        }
    }
}
