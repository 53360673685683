@import 'fonts';
@import 'variables';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Lato', sans-serif;
    overflow-x: hidden;
}

#root{
    height: 100vh;
}

.container{
    max-width: 1240px;
    margin: 0 auto;
}

.d-none{
    display: none !important;
}

.text-secondary{
    color: $secondary;
    
    &--dark{
        color: $secondary-dark;
    }
}

.text-bold{
    font-weight: $bold !important;;
}

.text-extra-bold{
    font-weight: $extra-bold !important;
}

.bg-primary{
    background-color: $primary;
}

.bg-secondary{
    background-color: $secondary;
    height: 100%;
}

.bg-gray{
    background-color: $gray !important;
}

.grayscale{
    filter: grayscale(1);
}

.spacer{
    height: 100%;
    width: 15px;
}

.icon{
    height: 20px;
    width: 30px;

    @media (max-width: 520px) {
        height: 15px;
        width: 15px;
    }
}

.card-content{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading{
    color: $white;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}

.fade-in{
    animation: fadeIn 0.4s linear;

    @keyframes fadeIn {
        0%{
            opacity: 0;
        }

        100%{
            opacity: 1;
        }
    }
}
