.card-tag{
    overflow: hidden;
    margin-bottom: 15px;

    h4{
        color: $secondary;
        font-weight: $bold;
        font-size: 16px;
        line-height: 17px;
        letter-spacing: 0.05em;
        margin-bottom: 5px;
        text-transform: uppercase;

        @media (max-width: 520px) {
            font-size: 14px;
        }
    }

    p{
        color: $primary;
        font-size: 13px;
        font-weight: $bold;
        line-height: 17px;
        letter-spacing: 0.05em;
        max-width: 130px;
        overflow: hidden;
        padding-left: 5px;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;

        @media (max-width: 520px) {
            font-size: 11px;
            max-width: 130px;
        }
    }

    &.full-width{
        grid-column: 1 / span 2;

        p{
            max-width: 100%;
        }
    }
}
