.modal{
    align-items: center;
    background: rgba(124, 81, 157, 0.4);
    display: none;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.5;
    will-change: transform;
    z-index: 9999;    

    &.is-open{
        display: flex !important;
        @extend .fade-in;
    }

    &-container{
        background: $secondary;
        border-radius: 50px;
        box-shadow: 10px 10px 0px $overlay-3;
        color: $white;
        height: 80%;
        overflow: hidden;
        padding: 40px;
        width: 40%;

        @media (max-width: 768px) {
            width: 70%;
        }
        
        @media (max-width: 520px) {
            padding: 40px 20px;
            width: 90%;
        }

    }

    &-header{
        margin-bottom: 30px;
        position: relative;

        &-title{
            font-family: $blogger;
            font-weight: $bold;
            font-size: 40px;
            line-height: 1.2;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            @media (max-width: 768px) {
                font-size: 32px;
            }

            @media (max-width: 520px) {
                font-size: 26px;
            }
        }

        &-btn{
            background: url('../../../svg/icon-close.svg');
            background-repeat: no-repeat;
            border: none;
            height: 21px;
            width: 17px;
            position: absolute;
            top: 2%;
            right: 0;

            &:hover{
                cursor: pointer;
            }
        }
    }

    &-content{
        height: 80%;
        overflow-y: auto;
        padding: 0 20px;

        &::-webkit-scrollbar{
            width: 5px;

            &-track{
                background: $gray;
                border-radius: 30px;
            }

            &-thumb{
                background: $secondary-dark;
                border-radius: 30px;
            }
        }

        p{
            margin-bottom: 15px;

            @media (max-width: 520px) {
                font-size: 14px;;
            }

           
        }

        ul{
            margin: 15px 0;
            padding-left: 20px;
            
            li{
                margin-bottom: 5px;
            }
        }

        h3{
            margin-bottom: 10px;
        }
    }
}