.actions-history{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    position: relative;
    overflow: hidden;
    transition: 2s cubic-bezier(0.4, 0, 0.2, 1);

    &-content{
        padding: 40px;

        @media (max-width: 520px) {
            padding: 0;
        }

        &--title{
            color: $secondary-dark;
            font-family: $blogger;
            font-size: 125px;
            letter-spacing: 0.05em;
            margin-bottom: 30px;
            opacity: 0.8;
            text-align: center;
            text-transform: uppercase;

            @media (max-width: 1024px) {
                font-size: 110px;
            }

            @media (max-width: 1024px) {
                font-size: 85px;
            }
        }

        &--text{
            color: $secondary-dark;
            font-size: 26px;
            font-weight: $bold;
            line-height: 150%;
            letter-spacing: 0.05em;
            margin: 0 auto 60px;
            text-align: center;
            width: 80%;

            @media (max-width: 1024px) {
                font-size: 18px;
            }

            @media (max-width: 520px) {
                font-size: 15px;
                text-align: start;
                width: 100%;
            }
        }
    }

    &-form{
        align-items: center;
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: space-evenly;
        margin: 40px auto;
        width: 80%;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 520px) {
            gap: 25px;
            width: 100%;
        }

        &-select{
            margin: 0;
            padding: 0;          
            position: relative;

            &::before{
                background-image: url('../../../svg/icon-select.svg');
                background-repeat: no-repeat;
                background-position: center center;
                content: '';
                height: 15px;
                width: 15px;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                pointer-events: none;
                z-index: 10;
            }

            select{
                display: flex;
                background: $secondary-dark;
                color: $white;
                border-radius: 15px;
                box-shadow: 4px 4px 0px $shadow50;
                font-family: $blogger;
                font-size: 22px;
                font-weight: $medium;
                letter-spacing: 0.05em;
                outline: none;
                padding: 10px 80px 10px 20px;
                position: relative;
                transition: 0.3s;
                width: 100%;
                appearance: none;
                /* for Firefox */
                -moz-appearance: none;
                /* for Safari, Chrome, Opera */
                -webkit-appearance: none;

                @media (max-width: 520px) {
                    font-size: 18px;
                    padding: 10px 20px;
                }
            }
        }
    }
    
    .filters{
        .tag-filter{
            background-color: $secondary-dark;
            color: $white;
        }
    }

    &-cards{
        align-items: center;
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
        justify-items: center;
        justify-content: space-evenly;
        padding: 40px;
        width: 100%;

        &::after {           
            background: linear-gradient(0deg, $primary 36%, rgba(0, 0, 0, 0) 65%);
            content: '';
            height: 400px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 0;
        }
        
        &.no-results::after{
            display: none;
        }        
        
        .slider-content{
            align-items: center;
            display: flex;
            justify-content: center;
            width: 100%;
        }

        @media (max-width: 1024px) {
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        @media (max-width: 520px) {
            padding: 20px 0;
        }
    }
}
