.modal-showcase{
    align-items: center;
    background: rgba(124, 81, 157, 0.3);
    display: none;
    height: 100vh;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    will-change: transform;
    z-index: 999;

    &.is-open{
        display: flex;
        @extend .fade-in
    }

    &-container{
        background-color: $secondary;
        box-shadow: 0px 5px 32px $shadow50;
        border-radius: 25px;
        display: flex;
        height: 100%;
        justify-content: space-evenly;
        justify-items: center;
        max-height: 580px;
        max-width: 910px;
        padding: 40px;
        position: relative;
        width: 100%;

        .modal-showcase-btn{
            background: url('../../../svg/icon-close.svg');
            background-repeat: no-repeat;
            border: none;
            height: 21px;
            width: 17px;
            position: absolute;
            top: 3%;
            right: 2%;

            &:hover{
                cursor: pointer;
            }
        }

        .slick-slider.slick-initialized{
            width: 100%;
        }

        .slick-prev {
            left: -100px;
        }

        .slick-next {
            right: -80px;
        }
        
        .slick-prev::before, .slick-next::before{
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            filter: drop-shadow(4px 4px 0px $shadow50);
            content: '';
            display: block;
            font-size: 40px;
            height: 40px;
            width: 40px;

            @media (max-width: 520px) {
                display: none;
            }
        }

        .slick-prev::before{
            background: url('../../../svg/icon-arrow-left.svg'), $gray;
            background-position: center;
            background-repeat: no-repeat;
        }

        .slick-next::before{
            background: url('../../../svg/icon-arrow-right.svg'), $gray;
            background-position: center;
            background-repeat: no-repeat;
        }
    
        .slick-dots{
            bottom: -60px;

            li button:before{
                font-size: 14px;
                color: $gray;
                padding-top: 20px
            }

            li.slick-active button:before {
                color: $secondary-dark;
            }
        }

        .slick-slide{
            padding: 0 15px;
        }
    }


    &-slider{
        color: $white;
        display: grid;
        gap: 20px;
        grid-template-areas:
            "description image"
            "description info"
            "description area";
        grid-template-columns: 45% 55%;
        grid-template-rows: auto;
        height: 100%;
        justify-content: space-evenly;
        justify-items: center;
        position: relative;
        width: 100%;

        .modal-showcase-content{
            grid-area: description;
            line-height: 1.25;
            letter-spacing: 0.05em;
            max-height: 450px;
            overflow: auto;
            padding: 0 20px;
            width: 100%;

            &--title{
                font-family: $blogger;
                font-weight: 700;
                font-size: 22px;                
                margin-bottom: 20px;
                text-transform: uppercase;
            }
        }

        .modal-showcase-image{
            border-radius: 15px;
            grid-area: image;
            height: 320px;
            object-fit: cover;
            width: 100%;
        }

        .modal-showcase-tags{
            
            &--info{
                grid-area: info;
            }

            &--area{
                grid-area: area;
            }

            &--info,
            &--area{
                align-items: flex-start;
                column-gap: 15px;
                display: flex;
                justify-content: space-between;
                width: 100%;

                .showcase-tag{
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    font-family: $blogger;
                    justify-content: center;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
    
                    &--title{
                        background: $primary;
                        border-radius: 5px;
                        color: $white;
                        font-size: 14px;
                        font-weight: $bold;
                        margin-bottom: 10px;
                        padding: 5px 10px;
                        width: 100%;
                    }

                    &--content{
                        font-size: 11px;
                        font-weight: $bold;

                        a{
                            color: inherit;
                            text-decoration: none;

                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }            
        }
    }
}