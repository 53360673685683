.banner{
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: calc(100vh - 98px);
    padding: 20px 40px;
    position: relative;

    @media (max-width: 768px) {
        padding: 80px 40px;
    }

    &-container{
        align-items: center;
        background: url('../../../images/banner.png');
        background-position: 25% 20%;
        background-repeat: no-repeat;        
        background-size: 510px;
        display: flex;
        height: 100%;
        justify-content: flex-end;
        padding: 0 80px;
        width: 100%;

        @media (max-width: 1024px) {
        }

        @media (max-width: 520px) {
            background: url('../../../images/banner-mobile.png');
            background-position: 0% 30%;
            background-repeat: no-repeat;
            height: 100%; 
            padding: 0;
        }

        .banner-content{
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            width: 50%;
            
            @media (max-width: 768px) {
                width: 70%;
            }
            
            @media (max-width: 520px) {
                width: 90%;
            }
    
            &-title{
                color: $secondary;
                font-family: $blogger;
                font-size: 60px;
                font-weight: $medium;
                line-height: 1.2;
                margin-bottom: 30px;
                text-align: right;
                text-transform: uppercase;

                @media (max-width: 768px) {
                    font-size: 48px;
                }

                @media (max-width: 520px) {
                    font-size: 36px;
                }

                & > span {
                    font-weight: $bold;
                }
            }

            &-cta{
                @media (max-width: 768px) {
                    margin-top: 20px;
                }

                button {
                    margin-right: 35px;
                    width: fit-content;

                    @media (max-width: 520px) {
                        font-size: 16px;
                        margin-right: 20px;
                        padding: 5px 25px;
                    }
                }
            }            
        }

        .icon-scroll{
            background-image: url('../../../svg/icon-scroll.svg');
            background-position: center center;
            background-repeat: no-repeat;
            height: 70px;
            position: absolute;
            left: 50%;
            bottom: 5%;
            width: 42px;

            @media (max-width: 1024px) {
                display: none;
            }
            
            &:hover{
                cursor: pointer;
            }

            .icon-vertical-arrow{
                background-image: url('../../../svg/icon-vertical-arrow.svg');
                background-position: center center;
                background-repeat: no-repeat;
                width: 8px;
                height: 24px;
                position: absolute;
                bottom: 13px;
                left: 40%;
                animation: move 1.5s linear infinite;

                @keyframes move {
                    0%{
                        transform: translateY(-5px);
                    }
                    50%{
                        transform: translateY(0);
                    }
                    100%{
                        transform: translateY(-5px);
                    }                
                }
            }
        }
    }   
}
